import React from "react"

import Transition from "./Transition/"
import Hero from "./Hero/"
// import HowItWorks from "./HowItWorks/"
// import Flavors from "./Flavors"
// import Instagram from "./Instagram"

const LoadableHowItWorks = React.lazy(() => import("./HowItWorks"))
const LoadableFlavors = React.lazy(() => import("./Flavors"))
const LoadableInstagram = React.lazy(() => import("./Instagram"))

export default function FrontPage(props) {
  const isSSR = typeof window === "undefined"

  return (
    <>
      <Hero />

      <Transition transitionId={`980sd7fs9df8908`} />

      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <LoadableHowItWorks />
          <LoadableFlavors />
        </React.Suspense>
      )}

      <Transition reverse={true} transitionId={`d89f7gd98f7g`} />

      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <LoadableInstagram />
        </React.Suspense>
      )}
    </>
  )
}
