import React from "react"

import SEO from "../components/seo"
import FrontPage from "./front-page"

const IndexPage = () => (
  <>
    <SEO title="Shakes and Smoothies" />
    <FrontPage />
  </>
)

export default IndexPage
